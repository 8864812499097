import styled from 'styled-components';

const RadioWrapper = styled.label`
  font-family: var(--primary-font);
  color: ${({ theme }) => theme.colors.black};
  position: relative;
  cursor: pointer;
  font-size: 14px;

  /* STATE: DISABLED */
  color: ${({ disabled, theme }) => disabled && `${theme.colors.grey500}`};

  .radio {
    &__default-box {
      opacity: 0;
      height: 15px;
      width: 15px;
    }

    &__checkmark {
      height: 15px;
      width: 15px;
      border-radius: 100%;
      background-color: ${({ theme }) => theme.colors.grey300};
      border-width: 1px;
      border-style: solid;
      border-color: ${({ theme }) => theme.colors.grey500};

      /* STATE: DISABLED */
      background-color: ${({ disabled, theme }) => disabled && `${theme.colors.grey300}`};
      border-color: ${({ disabled, theme }) => disabled && `${theme.colors.grey500}`};
    }
  }

  :hover .radio__default-box ~ .radio__checkmark {
    background-color: var(--primary-200);
    border-color: var(--primary-500);

    /* STATE: DISABLED */
    background-color: ${({ disabled, theme }) => disabled && `${theme.colors.grey300}`};
    border-color: ${({ disabled, theme }) => disabled && `${theme.colors.grey500}`};
  }

  .radio__default-box:checked ~ .radio__checkmark {
    background-color: ${({ theme }) => theme.colors.white};
    border-color: var(--primary-500);
    height: 15px;
    width: 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    :after {
      content: '';
      height: 9px;
      width: 9px;
      border-radius: 100%;
      background-color: var(--primary-500);

      /* STATE: DISABLED */
      background-color: ${({ disabled, theme }) => disabled && `${theme.colors.grey400}`};
    }

    :hover {
      border-color: var(--primary-400);

      /* STATE : DISABLED */
      background-color: ${({ disabled, theme }) => disabled && `${theme.colors.grey200}`};
      border-color: ${({ disabled, theme }) => disabled && `${theme.colors.grey400}`};

      :after {
        background-color: var(--primary-400);

        /* STATE: DISABLED */
        background-color: ${({ disabled, theme }) => disabled && `${theme.colors.grey400}`};
      }
    }

    /* STATE: DISABLED */
    background-color: ${({ disabled, theme }) => disabled && `${theme.colors.grey200}`};
    border-color: ${({ disabled, theme }) => disabled && `${theme.colors.grey400}`};
  }
`;

export default RadioWrapper;
