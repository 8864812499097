import React from 'react';
import { signIn } from 'next-auth/react';

import Button from 'components/Button/Button';
import Text from 'components/Text';
import { insertRouteAfter } from 'utils/insertRouteAfter';

import LoginImages from './LoginImages';
import MarketingPermissions from './MarketingPermissions';
import MarketingHelp from './MarketingHelp';
import oktaClient from './oktaClient';

export default {
  MetaValues: {
    title: 'OneVision',
    faviconPath: '/static/icons/favicon-onevision.ico',
    defaultCss: 'onevision.css',
  },
  LoginImages,
  LoginLogoPath: '/static/images/onevision/loginpage-logo-onevision.svg',
  MarketingPermissions,
  LegalMentions: (t) => (
    <>
      <Button appearance="simple" as="a" href="/static/doc/mentions-legales-onevision.pdf" download>
        <Text size="p2" className="footer__link">
          {t('footer-legal-notices')}
        </Text>
      </Button>
      <span className="footer__separator" />
    </>
  ),
  MarketingHelp,
  helpUrl: 'https://onevision.help.hyperspread.com/',
  SsoButton: (t) => (
    <Button onClick={() => signIn(oktaClient.providerId)}>{t('use-other-method')}</Button>
  ),
  AdditionalNavigation: {
    actions: (actions) => actions.reduce((acc, curr) => [...acc, curr], []),
    nav: (routes) => {
      const withMarketing = insertRouteAfter(routes, 'planning', {
        i18n_value: 'admin_relational_marketing',
        path: '/marketing?view=list',
        permission: 'hyp.marketing.list',
        active_paths: ['/marketing'],
        feature: 'campaigns',
      });
      // eslint-disable-next-line camelcase
      const statPage = withMarketing.find(({ i18n_value }) => i18n_value === 'statistics');
      const withStatsMarketing = insertRouteAfter(statPage.children, 'admin_campaigns', {
        i18n_value: 'admin_relational_marketing',
        path: '/statistics/marketing',
        permission: 'hyp.marketing.manage',
        feature: 'campaigns',
      });
      statPage.children = withStatsMarketing;

      return withMarketing;
    },
    admin: (routes) =>
      insertRouteAfter(routes, 'admin_campaigns', {
        i18n_value: 'admin_relational_marketing',
        feature: 'campaigns',
        path: '/admin/marketing',
        permission: 'hyp.marketing.manage',
        children: [
          {
            i18n_value: 'admin_list',
            feature: 'campaigns',
            path: '/admin/marketing',
            permission: 'hyp.marketing.manage',
          },
          {
            i18n_value: 'admin_category',
            feature: 'campaigns',
            path: '/admin/marketing/categories',
            permission: 'hyp.marketing.manage',
          },
        ],
      }),
  },
};
