const generateConfig = require('./generateConfig');

const extensions = [
  require('/opt/atlassian/pipelines/agent/build/extensions/onevision/extension.plugin.js').default
];

global.plugins = generateConfig(extensions);

const toBeOverrided = (path) => global.plugins.length && global.plugins[0][path];

const middleware = (init, path) => {
  const isOverridable = toBeOverrided(path);
  const plugin = isOverridable ? global.plugins[0][path] : undefined;

  return plugin || init;
};

global.middleware = middleware;
