import Accordion from 'components/Accordion/Accordion';
import React from 'react';
import PermissionCheckbox from 'screens/Admin/Permissions/components/PermissionCheckbox';

const MarketingPermissions = (t, checkboxProps) => (
  <Accordion title={t('ui:nav.admin_relational_marketing')} isOpenByDefault>
    <PermissionCheckbox
      permission="hyp.marketing.list"
      childPerms={['hyp.marketing.manage']}
      feature="campaigns"
      {...checkboxProps}
    />
    <PermissionCheckbox
      permission="hyp.marketing.manage"
      dependency="hyp.marketing.list"
      feature="campaigns"
      {...checkboxProps}
    />
  </Accordion>
);

export default MarketingPermissions;
