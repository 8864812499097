import React from 'react';
import PropTypes from 'prop-types';
import { colors as defaultColors } from 'tokens';
import { BsInfoCircleFill } from 'react-icons/bs';
import clsx from 'clsx';

import Tooltip from 'components/Tooltip/Tooltip';

import addBindTo from '../Provider/hocs/addBindTo';
import RadioWrapper from './RadioWrapper';

const Radio = ({
  className,
  children,
  id,
  disabled,
  name,
  value,
  theme,
  error,
  tooltip,
  readOnly,
  ...rest
}) => {
  return (
    <RadioWrapper
      className={clsx(
        'radio flex items-center gap-2',
        className,
        readOnly && 'pointer-events-none',
      )}
      disabled={disabled}
      theme={theme}
    >
      <input
        className="radio__default-box hidden"
        type="radio"
        id={id}
        disabled={disabled}
        name={name}
        value={value}
        readOnly={readOnly}
        {...rest}
      />
      <span className="radio__checkmark" />
      {children}
      {error && <span>{error}</span>}
      {tooltip && (
        <Tooltip position="right" text={tooltip}>
          <BsInfoCircleFill className="cursor-pointer text-xl text-primary-500" />
        </Tooltip>
      )}
    </RadioWrapper>
  );
};

Radio.defaultProps = {
  className: '',
  children: '',
  error: '',
  disabled: false,
  theme: { colors: defaultColors },
  tooltip: null,
  readOnly: false,
};

Radio.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  error: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  disabled: PropTypes.bool,
  tooltip: PropTypes.string,
  readOnly: PropTypes.bool,
  theme: PropTypes.shape({
    colors: PropTypes.shape({
      primary100: PropTypes.string,
    }),
  }),
};

export default addBindTo((context) => ({
  theme: context.theme,
}))(Radio);
