import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'i18n';
import clsx from 'clsx';
import { uniqBy } from 'lodash';

import Checkbox from 'components/Checkbox/Checkbox';
import Text from 'components/Text/Text';
import Tooltip from 'components/Tooltip/Tooltip';
import Radio from 'components/Radio/Radio';

const depthsMap = {
  0: '',
  1: 'ml-8',
  2: 'ml-16',
  3: 'ml-24',
};

function getIsPermissionEnabledInSettings(permission, settings, choices) {
  if (!settings) return undefined;

  if (choices) {
    const res = choices.map((choice) =>
      getIsPermissionEnabledInSettings(choice.permission, settings),
    );

    if (res.some((enabled) => enabled !== undefined)) {
      return res.some((enabled) => enabled);
    }

    return undefined;
  }

  const permSetting = settings.find(({ name }) => name === permission);

  if (!permSetting) return undefined;

  return permSetting.enabled;
}

function getNewPermSetting(baseSettings, permission, newState, choices) {
  // Remove the permission setting and let parent handle it
  if (newState === undefined) return baseSettings.filter(({ name }) => name !== permission);

  choices?.forEach(
    (choice) =>
      baseSettings.every(({ name }) => choice.permission !== name) &&
      baseSettings.push({ name: choice.permission, enabled: false }),
  );

  if (!newState && choices) {
    const permissionsToDisabled = choices.map((choice) => choice.permission);

    return baseSettings.map(({ name, enabled }) =>
      permissionsToDisabled.includes(name) ? { name, enabled: false } : { name, enabled },
    );
  }

  // or set the setting to the new value
  let alreadyIn = false;
  const newSettings = baseSettings.map(({ name, enabled }) => {
    const isPermission = (choices ? choices[0].permission : permission) === name;
    if (isPermission) alreadyIn = true;

    return {
      name,
      enabled: isPermission ? newState : enabled,
    };
  });
  if (!alreadyIn) {
    newSettings.push({ name: choices ? choices[0].permission : permission, enabled: newState });
  }

  return newSettings;
}

function getNewChoicePermSetting(baseSettings, permission, choices) {
  const newSettings = baseSettings;
  const currentPermissionSetting = baseSettings.find(({ name }) => name === permission);

  if (currentPermissionSetting) currentPermissionSetting.enabled = true;
  else newSettings.push({ name: permission, enabled: true });

  const permissionsToDisabled = choices
    .filter((choice) => choice.permission !== permission)
    .map((choice) => choice.permission);

  return newSettings.map((setting) =>
    permissionsToDisabled.includes(setting.name) ? { ...setting, enabled: false } : setting,
  );
}

function PermissionCheckbox({
  t,
  permission,
  permSettings,
  parentPermSettings,
  setFieldValue,
  dependency,
  childPerms,
  userPermissions,
  readOnly,
  clientFeatures,
  hideBadge,
  depth: depthProps,
  className,
  label,
  hint,
  features,
  choices,
}) {
  const mergeSettings = useMemo(() => {
    const settings = uniqBy([...permSettings, ...(parentPermSettings || [])], 'name');

    // this is to ensure the first enabled choice is selected
    if (choices) {
      const indexFirstEnabled = choices.findIndex((choice) =>
        getIsPermissionEnabledInSettings(choice.permission, settings),
      );
      const permsToDisable = choices
        .filter((_c, i) => i !== indexFirstEnabled)
        .map((c) => c.permission);

      return settings.map((s) => (permsToDisable.includes(s.name) ? { ...s, enabled: false } : s));
    }

    return settings;
  }, [permSettings, parentPermSettings, choices]);

  const { isDisabled, isPermEnabled, parentPermEnabled, depth, disabledCause } = useMemo(() => {
    function getIsPermEnabledInRole(permToCheck) {
      // If perm is enabled in current role
      if (getIsPermissionEnabledInSettings(permToCheck, permSettings)) return true;
      // Or not disabled in current && enabled in parent

      if (
        parentPermSettings &&
        getIsPermissionEnabledInSettings(permToCheck, permSettings) !== false &&
        getIsPermissionEnabledInSettings(permToCheck, parentPermSettings)
      ) {
        return true;
      }

      return false;
    }

    // eslint-disable-next-line no-nested-ternary
    const depthMemo = depthProps === null ? (dependency ? 1 : 0) : depthProps;
    const dependecyEnabled = dependency ? getIsPermEnabledInRole(dependency) : true;
    const permEnabled = getIsPermissionEnabledInSettings(permission, permSettings, choices);

    const featureDisabled =
      features.length > 0 && !features.every((feature) => clientFeatures.includes(feature));
    const isParentPermEnabled = parentPermSettings
      ? getIsPermissionEnabledInSettings(permission, parentPermSettings, choices) || false
      : undefined;

    const isUserHasPermissions = choices
      ? choices.some((choice) => userPermissions?.includes(choice.permission))
      : userPermissions?.includes(permission);

    const disabled = !dependecyEnabled || (!isUserHasPermissions && !readOnly) || featureDisabled;

    const getDisabledCause = () => {
      if (readOnly) return null;
      if (featureDisabled) return t('disabled-feature');
      if (userPermissions && !userPermissions.includes(permission)) {
        return t('disabled-permission-right');
      }

      if (!dependecyEnabled) return t('disabled-dependency');

      return null;
    };

    return {
      depth: depthMemo,
      parentPermEnabled: isParentPermEnabled,
      isDisabled: disabled,
      isPermEnabled:
        dependecyEnabled && (permEnabled || (permEnabled === undefined && isParentPermEnabled)),
      disabledCause: disabled ? getDisabledCause() : null,
    };
  }, [
    depthProps,
    dependency,
    parentPermSettings,
    permSettings,
    permission,
    readOnly,
    userPermissions,
    clientFeatures,
    features,
    t,
    choices,
  ]);

  return (
    <div className="w-fit">
      <Tooltip text={disabledCause} duration={[50, 50]} disabled={disabledCause === null}>
        <Checkbox
          checked={Boolean(isPermEnabled)}
          id={permission}
          fullWidth={false}
          disabled={Boolean(isDisabled)}
          name={permission}
          readOnly={readOnly}
          className={clsx('relative mb-2 w-fit', className, depthsMap[depth])}
          onChange={() => {
            const enabled = isPermEnabled === undefined ? !parentPermEnabled : !isPermEnabled;

            let newSettings = getNewPermSetting(permSettings, permission, enabled, choices);
            if (!enabled && childPerms) {
              childPerms.forEach((childPerm) => {
                newSettings = getNewPermSetting(newSettings, childPerm, false, choices);
              });
            }

            return setFieldValue('permissionsSettings', newSettings);
          }}
        >
          {parentPermSettings &&
            isPermEnabled !== parentPermEnabled &&
            !isDisabled &&
            !hideBadge && (
              <div className="absolute -top-1 left-4">
                <Tooltip text={t('modifier-perm-tooltip')} duration={[50, 50]}>
                  <div className="h-2 w-2 rounded-full border border-solid border-white bg-yellow-600" />
                </Tooltip>
              </div>
            )}
          <Text className={clsx('font-secondary', isDisabled && 'text-gray-600')}>
            {label || t(permission.replaceAll('.', '-'))}
          </Text>
        </Checkbox>
      </Tooltip>
      {choices?.map((choice) => (
        <div key={choice.permission} className={clsx('relative mb-2 w-fit', depthsMap[depth + 1])}>
          <Radio
            id={choice.permission}
            value={choice.permission}
            checked={getIsPermissionEnabledInSettings(choice.permission, mergeSettings)}
            disabled={!isPermEnabled}
            readOnly={readOnly}
            onClick={() =>
              setFieldValue(
                'permissionsSettings',
                getNewChoicePermSetting(mergeSettings, choice.permission, choices),
              )
            }
            name={permission}
          >
            {t(choice.permission.replaceAll('.', '-'))}
          </Radio>
          {choice.description && <Text className="ml-6 text-gray-600">{choice.description}</Text>}
        </div>
      ))}
      {hint && <Text className="text-gray-600">{hint}</Text>}
    </div>
  );
}

PermissionCheckbox.defaultProps = {
  dependency: null,
  childPerms: null,
  readOnly: false,
  features: [],
  clientFeatures: [],
  depth: null,
  className: '',
  label: null,
  hint: null,
  userPermissions: null,
  hideBadge: false,
};

PermissionCheckbox.propTypes = {
  t: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  dependency: PropTypes.string,
  childPerms: PropTypes.arrayOf(PropTypes.string),
  permission: PropTypes.string.isRequired,
  depth: PropTypes.number,
  label: PropTypes.string,
  hint: PropTypes.string,
  hideBadge: PropTypes.bool,
  permSettings: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string.isRequired, enabled: PropTypes.bool.isRequired }),
  ).isRequired,
  parentPermSettings: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string.isRequired, enabled: PropTypes.bool.isRequired }),
  ).isRequired,
  userPermissions: PropTypes.arrayOf(PropTypes.string),
  readOnly: PropTypes.bool,
  clientFeatures: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  features: PropTypes.arrayOf(PropTypes.string),
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      permission: PropTypes.string.isRequired,
      description: PropTypes.bool.isRequired,
    }),
  ).isRequired,
};

export default withTranslation('permissions')(PermissionCheckbox);
