import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { colors as defaultColors } from 'tokens';
import { BsChevronDown } from 'react-icons/bs';
import addBindTo from '../Provider/hocs/addBindTo';
import AccordionWrapper from './AccordionWrapper';
import Title from '../Title/Title';

const Accordion = ({
  className,
  titleClassName,
  children,
  title,
  theme,
  icon,
  actions,
  noPadding,
  isOpenByDefault,
}) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(isOpenByDefault);

  function updateAccordionState() {
    setIsAccordionOpen(!isAccordionOpen);
  }

  return (
    <AccordionWrapper
      className={className}
      isAccordionOpen={isAccordionOpen}
      theme={theme}
      noPadding={noPadding}
    >
      <div className="accordion">
        <div
          className={clsx('accordion-title gap-4', titleClassName)}
          onClick={updateAccordionState}
          onKeyPress={updateAccordionState}
          role="button"
          tabIndex={0}
        >
          <Title size="h4" className={titleClassName}>
            {title}
          </Title>
          <div className="flex gap-4 items-center">
            <div className="flex gap-4 ml-auto">{actions}</div>
            <div className="toggle-icon ml-auto" aria-expanded={isAccordionOpen}>
              {icon}
            </div>
          </div>
        </div>
        <div className="accordion-contents" aria-expanded={!isAccordionOpen}>
          <div>{children}</div>
        </div>
      </div>
    </AccordionWrapper>
  );
};

Accordion.defaultProps = {
  className: '',
  titleClassName: '',
  title: '',
  children: '',
  noPadding: false,
  icon: <BsChevronDown />,
  theme: { colors: defaultColors },
  isOpenByDefault: false,
  actions: [],
};

Accordion.propTypes = {
  className: PropTypes.string,
  titleClassName: PropTypes.string,
  noPadding: PropTypes.bool,
  // icon can be a string or a React component
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
  actions: PropTypes.arrayOf(PropTypes.node),
  theme: PropTypes.shape({
    colors: PropTypes.shape({
      primary100: PropTypes.string,
    }),
  }),
  isOpenByDefault: PropTypes.bool,
};

export default addBindTo((context) => ({
  theme: context.theme,
}))(Accordion);
