/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { BsCheck } from 'react-icons/bs';

const Checkbox = ({
  className,
  children,
  id,
  disabled,
  readOnly,
  name,
  error,
  value,
  checked,
  icon,
  uncheckedIcon,
  top,
  fullWidth,
  ...rest
}) => {
  const pointer = disabled ? 'cursor-not-allowed' : 'cursor-pointer';

  return (
    <div
      className={clsx(
        'flex flex-col space-y-2 group',
        fullWidth ? 'w-full' : 'max-w-max',
        readOnly && 'pointer-events-none',
      )}
    >
      <label
        className={clsx(
          'flex gap-2',
          fullWidth && 'w-full',
          !readOnly && pointer,
          top ? 'items-start' : 'items-center',
          className,
        )}
        htmlFor={id}
        disabled={disabled}
        onKeyPress={() => undefined}
        onClick={(event) => event.stopPropagation()}
      >
        <input
          type="checkbox"
          className="invisible sr-only"
          id={id}
          disabled={disabled}
          name={id}
          value={value}
          readOnly={readOnly}
          {...rest}
          defaultChecked={checked}
        />
        <div
          className={clsx(
            'min-w-5 min-h-5 w-5 h-5 flex items-center justify-center rounded border border-solid border-gray-500 transition-all',
            !disabled &&
              !error &&
              'group-hover:bg-primary-200 group-hover:border-primary-500 cursor-pointer',
            readOnly ? 'cursor-default' : pointer,
            checked && !disabled ? '!border-primary-400 bg-primary-500' : 'bg-white/80',
            error && !disabled && 'group-hover:bg-danger-300 group-hover:border-danger-500',
            checked && error && !disabled && '!border-danger-400 bg-danger-500',
            disabled && 'cursor-not-allowed bg-gray-400',
          )}
        >
          {checked ? icon || <BsCheck className="text-white text-lg" /> : uncheckedIcon}
        </div>
        {children}
      </label>
      {error && <p className="text-xs text-danger-500 font-secondary">{error}</p>}
    </div>
  );
};

Checkbox.defaultProps = {
  className: null,
  children: null,
  disabled: false,
  error: null,
  value: '',
  top: false,
  checked: false,
  fullWidth: true,
  icon: null,
  uncheckedIcon: null,
  readOnly: false,
};

Checkbox.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  top: PropTypes.bool,
  icon: PropTypes.node,
  uncheckedIcon: PropTypes.node,
  checked: PropTypes.bool,
  fullWidth: PropTypes.bool,
  readOnly: PropTypes.bool,
};

export default Checkbox;
