import React, { useMemo, useState } from 'react';
import { Button } from 'flex';
import PropTypes from 'prop-types';
import { withTranslation } from 'i18n';
import { BiTargetLock } from 'react-icons/bi';
import { RiMapPinLine } from 'react-icons/ri';
import { MdOutlineTouchApp } from 'react-icons/md';
import { HiOutlineQuestionMarkCircle } from 'react-icons/hi';

import { Portal } from 'react-portal';
import useZendeskWidgetCorrector from 'components/hooks/useZendeskWidgetCorrector';
import Modal from 'components/Modal/Modal';
import Title from 'components/Title/Title';
import Text from 'components/Text/Text';
import { BsX } from 'react-icons/bs';

const MarketingHelp = ({ t }) => {
  const [scopeModaleOpen, setScopeModaleOpen] = useState(false);
  useZendeskWidgetCorrector({ zIndex: -1 });
  const containerClass =
    'w-16 h-16 p-2 flex flex-col gap-1 items-center justify-center bg-black bg-gray-800 text-white opacity-8 cursor-pointer';
  const buttons = useMemo(
    () => [
      {
        icon: BiTargetLock,
        label: 'jade',
        onClick: () =>
          window.open('https://cmf.ope.dcs3.renault.com/apps/dashboards/project', '_blank'),
      },
      {
        icon: RiMapPinLine,
        label: t('analytics:catalog').toLowerCase(),
        onClick: () => window.open('https://www2.gocadservices.com/renault/', '_blank'),
      },
      {
        icon: MdOutlineTouchApp,
        label: `${t('common:contact').toLowerCase()} Scope`,
        onClick: () => window.open('https://renaultscope.zendesk.com/hc/fr/requests/new', '_blank'),
      },
      {
        icon: HiOutlineQuestionMarkCircle,
        label: 'faq scope',
        onClick: () => window.open('https://renaultscope.zendesk.com/hc/fr', '_blank'),
      },
    ],
    [t],
  );

  return (
    <Portal node={document && document.getElementsByTagName('header')[0]}>
      <div className="absolute right-0 flex flex-col gap-2" style={{ top: '300%' }}>
        {buttons.map((b, id) => (
          <div
            className={containerClass}
            key={buttons.label}
            onClick={b.onClick}
            onKeyDown={b.onClick}
            role="button"
            tabIndex={id}
          >
            <b.icon className="h-full w-full" />
            <p className="text-center font-primary" style={{ fontSize: 10 }}>
              {b.label}
            </p>
          </div>
        ))}
        <Modal
          visible={scopeModaleOpen}
          className="m-auto h-fit w-fit rounded bg-white"
          close={() => setScopeModaleOpen(false)}
        >
          <div className="flex w-full flex-col gap-2">
            <div className="relative flex w-full border-b border-solid border-gray-500 p-4">
              <div className="m-auto">
                <Title size="h4">{t('common:contact')} Scope</Title>
              </div>
              <BsX
                className="absolute top-2 right-2 text-lg"
                onClick={() => setScopeModaleOpen(false)}
              />
            </div>
            <div className="flex w-full flex-col gap-4 py-4 px-8">
              <div className="flex flex-col items-center gap-1">
                <Text>{t('scope.0')}</Text>
                <Text className="font-bold">{t('scope.1')}</Text>
              </div>
              <div className="flex flex-col items-center gap-2">
                <div className="flex">
                  <Text>{t('scope.2')}</Text>
                  <Text className="font-bold"> 01 47 11 10 00</Text>
                </div>
                <Text>{t('scope.3')}:</Text>
                <Button
                  theme="white"
                  onClick={() =>
                    window.open('https://renaultscope.zendesk.com/hc/fr/requests/new', '_blank')
                  }
                >
                  <Text>{t('scope.4')}</Text>
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </Portal>
  );
};

MarketingHelp.getInitialProps = async () => ({
  namespacesRequired: ['ui'],
});

MarketingHelp.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('ui')(MarketingHelp);
