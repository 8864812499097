import { useEffect } from 'react';

const defaultState = {
  zIndex: 99998,
  offset: {
    vertical: '0px',
    horizontal: '0px',
  },
};

export default function useZendeskWidgetCorrector(fix) {
  useEffect(() => {
    const doFix = (window) => {
      window?.zE?.('webWidget', 'updateSettings', { webWidget: fix });
      window?.zE?.hide?.();
    };

    if (!window || !window.zE) {
      // wait for zendesk to load
      const interval = setInterval(() => {
        if (window.zE) {
          clearInterval(interval);
          doFix(window);
        }
      }, 5000);
    } else {
      doFix(window);
    }

    return () => {
      window?.zE?.('webWidget', 'updateSettings', { webWidget: defaultState });
      window?.zE?.show?.();
    };
  }, [fix]);
}
