/* eslint-disable indent */
import styled from 'styled-components';

const AccordionWrapper = styled.div`
  text-decoration: none;
  .accordion {
    background-color: ${({ theme }) => theme.colors.white};

    .accordion-title {
      padding: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      :hover {
        border: none;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
        transition: box-shadow 150ms ease-in-out;
      }

      .toggle-icon {
        transition: all 0.35s ease;

        i {
          font-size: 36px;
          color: var(--primary-500);
        }

        &[aria-expanded='true'] {
          transform: rotateZ(-180deg);
        }
      }

      ${({ theme, isAccordionOpen }) =>
        isAccordionOpen === true
          ? `border-bottom: 1px solid ${theme.colors.grey500};`
          : `border-top: 1px solid ${theme.colors.grey500}`}
    }

    .accordion-contents {
      overflow: hidden;
      max-height: 9999px;
      transition: max-height 1s ease-in-out;

      & > div {
        ${({ noPadding }) => (noPadding === false ? `padding: 16px;` : ``)}
      }
      &[aria-expanded='true'] {
        max-height: 0px;
        transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
      }
    }
  }
`;

export default AccordionWrapper;
